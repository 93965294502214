import { useUserContext } from "@/context/UserContext";

import {
  getRecommend,
  getLastUpdated,
  fetchMostViewed,
  fetchLargestGalleries,
  fetchMostCommented,
  fetchFeatured,
  updateFeatured,
  fetchGalleries,
  fetchGalleriesSelect,
  upload,
  newGallery,
  subscribeGallery,
  fetchSpecials,
} from "@/services/GalleriesService";

function useGalleries() {
  const { userToken } = useUserContext();

  const getRecommendedGalleries = async (
    page: number,
    excludedIds: Array<string>
  ) => {
    return await getRecommend(page, excludedIds).then((res) => {
      return res.data;
    });
  };

  async function getRecentlyUpdated() {
    return await getLastUpdated().then((res) => {
      return res.data;
    });
  }

  async function getMostViewed() {
    return await fetchMostViewed().then((res) => {
      return res.data;
    });
  }

  async function getFeatured() {
    return await fetchFeatured().then((res) => {
      return res.data;
    });
  }

  async function getGalleriesSelect() {
    return fetchGalleriesSelect().then((res) => {
      return res.data;
    });
  }

  async function putFeatured(featured: Array<any>) {
    return await updateFeatured(featured, userToken).then((res) => {
      return res;
    });
  }

  async function getLargestGalleries() {
    return await fetchLargestGalleries().then((res) => {
      return res.data;
    });
  }

  async function getMostCommented() {
    return await fetchMostCommented().then((res) => {
      return res.data;
    });
  }

  async function getGalleries() {
    return fetchGalleries().then((res) => {
      return res.data.data;
    });
  }
  async function getSpecials() {
    return fetchSpecials().then((res) => {
      return res.data;
    });
  }

  async function uploadImages(formDataList: Array<FormData>, token: string) {
    return upload(formDataList, token).then((res) => {
      return res;
    });
  }

  async function createNewGallery(formData: FormData, token: string) {
    return newGallery(formData, token).then((res) => {
      return res;
    });
  }

  async function subscribeToGallery(galleryId: string) {
    return subscribeGallery(galleryId, userToken).then((res) => {
      return res;
    });
  }

  return {
    getFeatured,
    putFeatured,
    getGalleriesSelect,
    getRecommendedGalleries,
    getRecentlyUpdated,
    getMostViewed,
    getLargestGalleries,
    getMostCommented,
    getGalleries,
    uploadImages,
    createNewGallery,
    subscribeToGallery,
    getSpecials,
  };
}

export default useGalleries;
