import { useEffect, useRef, useState } from "react";
import "./MasonryCard.scss";
import { nameSlug } from "@/utils/Functions/NameSlug";
import Link from "next/link";

function MasonryCard(props: any) {
  const imageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  let link = "";
  if (props.gallery?.name) {
    link = `/${nameSlug(props.gallery.name)}`;
  }
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(imageRef.current!);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Link href={link}>
      <div className="recommend-gallery-card">
        <img
          ref={imageRef}
          src={isVisible ? props.gallery?.avatar : ""}
          alt={props.gallery?.name || "photo"}
        />
        {link && (
          <div className="gallery-info">
            {props.location && props.location === "categories" && (
              <h2>{props.gallery?.name}</h2>
            )}
            {!props.location && props.gallery?.name && (
              <h3>{props.gallery?.name}</h3>
            )}
            {props.gallery?.photos && <h4>{props.gallery?.photos} images</h4>}
          </div>
        )}
      </div>
    </Link>
  );
}

export default MasonryCard;
